.form-control {
  color: #525252;
}

.focused .form-control {
  color: #333333
}

.form-control:focus {
  color: #333333
}

.codex-editor {
    border-radius: 10px;
    border: 1px solid #d4d4d4;
}
